import { persistStore } from 'redux-persist';
import { Cookies } from 'react-cookie';
import { notification } from 'antd';

import { store } from 'src/store';
import { Notification } from 'src/utils';

const request = {
  errors: (e: any) => {
    const status = e.response?.status;
    const response = e.response?.data.errors;

    if (status) {
      switch (status) {
        case 403:
          return notification.error({
            message: 'Ocorreu um erro',
            description:
              'Parece que seu usuário não tem permissão para executar essa ação/ou acessar essa página.',
            duration: 0,
          });
        case 422:
          if (!!response.data.errors) {
            Object.entries(response.data.errors).map((item: any) => {
              return Notification.error({
                message: item[1],
              });
            });
          }
          return;
        case 401:
          const cookies = new Cookies();

          cookies.set('_jwtToken', undefined, {
            path: '/',
            maxAge: 0,
          });

          return !!store.getState().auth.token
            ? persistStore(store)
                .purge()
                .then(() => {
                  window.location.reload();
                  Notification.error({
                    message: 'Sessão expirada!',
                  });
                })
            : Notification.error({
                message:
                  'Sessão expirada! Insira as suas credenciais e logue novamente, por favor.',
              });
        default:
          return Notification.error({
            message:
              'Ocorreu um erro ao processar essa ação, tente novamente mais tarde!',
          });
      }
    } else {
      return Notification.error({
        message:
          'Ocorreu um erro ao processar essa ação, tente novamente mais tarde!',
      });
    }
  },
};

export default request;
