import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Modal, Skeleton, Divider } from 'antd';
import { FcTemplate } from 'react-icons/fc';

import { fetchTask } from 'src/store/tasks/actions';
import { stageTranslation } from 'src/utils/constants/tasks';

import Technicians from './components/technicians/technicians.component';
import Actions from './components/actions/actions.component';
import Advice from './components/advice/advice.component';
import Info from './components/info/info.component';

import { IProps } from './update-task.type';
import { TitleWrapper, Title, ListTitleWrapper } from './update-task.style';
import useTask from './update-task.use';

const UpdateComponent: React.FC<IProps> = (props) => {
  const { open, cardId, listId, title, handleEditing, editing } = props;

  const { handleTask, loading, task, handleModal } = useTask(props);

  useEffect(() => {
    open && !editing && handleTask(cardId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, editing]);

  return (
    <>
      <Modal
        forceRender
        styles={{
          body: { backgroundColor: '#dfe3e6' },
          header: { backgroundColor: '#dfe3e6' },
          footer: { backgroundColor: '#dfe3e6' },
          content: { backgroundColor: '#dfe3e6' },
        }}
        width={1000}
        title={
          <TitleWrapper>
            <Title>
              <FcTemplate size={30} className="me-2" />
              <b>{title}</b>
            </Title>
            <ListTitleWrapper>
              na lista de tarefas <b>{stageTranslation[listId].label}</b>
            </ListTitleWrapper>
            <Divider className="mt-2" />
          </TitleWrapper>
        }
        footer={false}
        open={open}
        onCancel={() => handleModal()}
      >
        {!loading && task ? (
          <Row gutter={[16, 16]}>
            <Col span={24} lg={17}>
              <Info cardId={cardId} listId={listId} />
              <Advice
                cardId={cardId}
                listId={listId}
                handleEditing={handleEditing}
                task={task}
              />
            </Col>
            <Col span={24} lg={7}>
              <Technicians
                cardId={cardId}
                handleTask={handleTask}
                task={task}
              />
              <Actions
                cardId={cardId}
                listId={listId}
                handleModal={handleModal}
              />
            </Col>
          </Row>
        ) : (
          <Skeleton active />
        )}
      </Modal>
    </>
  );
};

export default connect(undefined, { fetchTask })(UpdateComponent);
