import React from 'react';
import { Tag, Descriptions, Button } from 'antd';
import { FcInfo } from 'react-icons/fc';

import { urgencyDegree } from 'src/utils/constants/tasks';

import { IProps } from './descriptions.type';
import { Title } from './descriptions.style';

const UpdateComponent: React.FC<IProps> = (props) => {
  const { task, handleEditing } = props;

  return (
    <Descriptions
      bordered
      title={
        <Title>
          <FcInfo size={20} className="me-2" />
          <h4 className="m-0">Informações</h4>
        </Title>
      }
      size="small"
      extra={
        <Button size="small" type="default" onClick={handleEditing}>
          <small>EDITAR</small>
        </Button>
      }
    >
      <Descriptions.Item span={3} label="Descrição">
        {task.description}
      </Descriptions.Item>
      <Descriptions.Item span={1} label="Setor">
        <b>{task.sector.name}</b>
      </Descriptions.Item>
      <Descriptions.Item span={1} label="Célula">
        <b>{task.cell.name}</b>
      </Descriptions.Item>
      <Descriptions.Item span={1} label="Urgência">
        <Tag color={urgencyDegree[task.urgencyDegree].color}>
          {urgencyDegree[task.urgencyDegree]?.icon}
          <b>{urgencyDegree[task.urgencyDegree].label}</b>
        </Tag>
      </Descriptions.Item>
      <Descriptions.Item span={3} label="Patrimônio">
        <p className="mb-1">
          <b>ID:</b> {task.patrimonyId}
        </p>
        <p className="mb-1">
          <b>Descrição:</b> {task?.patrimony?.description || 'N/A'}
        </p>
        <p className="mb-1">
          <b>Modelo:</b> {task?.patrimony?.model || 'N/A'}
        </p>
        <p className="mb-1">
          <b>Serial Number:</b> {task?.patrimony?.serialNumber || 'N/A'}
        </p>
      </Descriptions.Item>
      <Descriptions.Item span={2} label="Operador">
        {task.operator.name}
      </Descriptions.Item>
      <Descriptions.Item span={2} label="Responsável">
        {task.user?.name || 'N/A'}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default UpdateComponent;
