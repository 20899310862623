import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Tag, Descriptions, Button, Form, Input, Select, Skeleton } from 'antd';
import { FcInfo } from 'react-icons/fc';
import type { SelectProps } from 'antd';

import { IReduxState } from 'src/store';
import { fetchTask, updateTask } from 'src/store/tasks/actions';
import { fetchCells } from 'src/store/cells/actions';
import { fetchOperators } from 'src/store/operators/actions';
import { fetchPatrimonies } from 'src/store/patrimonies/actions';
import { fetchSectors } from 'src/store/sectors/actions';
import { urgencyDegree, urgencyDegreeColor } from 'src/utils/constants/tasks';

import { IProps } from './form.type';
import { Title } from './form.style';
import useTask from './form.use';
import { rules, initialValues } from './form.utils';

const { TextArea } = Input;

type TagRender = SelectProps['tagRender'];

const UpdateComponent: React.FC<IProps> = (props) => {
  const { cardId } = props;
  const {
    handlePatrimonies,
    patrimonies,
    handleCells,
    cells,
    handleOperators,
    operators,
    handleSectors,
    sectors,
    handleSubmit,
    loading,
    handleTask,
    task,
    sending,
  } = useTask(props);

  useEffect(() => {
    handleTask(cardId);
    handlePatrimonies();
    handleCells();
    handleOperators();
    handleSectors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const user = useSelector((state: IReduxState) => state.auth?.user);

  const tagRender: TagRender = (props: any) => {
    const { label, value } = props;

    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag color={urgencyDegreeColor[value]} onMouseDown={onPreventMouseDown}>
        <b>{label}</b>
      </Tag>
    );
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return !loading && task && patrimonies && cells && sectors && operators ? (
    <Form
      layout="vertical"
      initialValues={initialValues(task)}
      onFinish={handleSubmit}
    >
      <Descriptions
        bordered
        size="small"
        title={
          <Title>
            <FcInfo size={20} className="me-2" />
            <h4 className="m-0">Informações</h4>
          </Title>
        }
        extra={
          <Button type="default" htmlType="submit" loading={sending}>
            <small>SALVAR</small>
          </Button>
        }
      >
        <Descriptions.Item span={3} label="Descrição">
          <Form.Item
            name="description"
            rules={rules.description}
            className="mt-2"
          >
            <TextArea placeholder="Motivo" rows={4} />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Setor">
          <Form.Item name="sectorId" rules={rules.sectorId}>
            <Select
              showSearch
              placeholder="Setores"
              optionFilterProp="children"
              filterOption={filterOption}
              options={sectors}
            />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Célula">
          <Form.Item name="cellId" rules={rules.cellId}>
            <Select
              showSearch
              placeholder="Célula"
              optionFilterProp="children"
              filterOption={filterOption}
              options={cells}
            />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Urgência">
          <Form.Item name="urgencyDegree" rules={rules.urgencyDegree}>
            <Select
              tagRender={tagRender}
              options={urgencyDegree}
              placeholder="Selecione..."
            />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Patrimônio">
          <Form.Item
            name="patrimonyId"
            rules={rules.patrimonyId}
            className="mt-2"
          >
            <Select
              showSearch
              placeholder="Patrimônio (Máquina)"
              optionFilterProp="children"
              filterOption={filterOption}
              options={patrimonies}
            />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Operador">
          <Form.Item
            name="operatorId"
            className="mt-2"
            rules={rules.operatorId}
          >
            <Select
              showSearch
              placeholder="Operador"
              optionFilterProp="children"
              filterOption={filterOption}
              options={operators}
            />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Responsável">
          <Form.Item name="userId" className="mt-2">
            <Input
              placeholder="Responsável"
              readOnly
              defaultValue={user.name}
            />
          </Form.Item>
        </Descriptions.Item>
      </Descriptions>
    </Form>
  ) : (
    <Skeleton active />
  );
};

const mapStateToProps = (state: IReduxState) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  fetchTask,
  fetchCells,
  fetchOperators,
  fetchPatrimonies,
  fetchSectors,
  updateTask,
})(UpdateComponent);
