import React, { useEffect } from 'react';
import { Divider, Button, Row, Col } from 'antd';
import SkeletonButton from 'antd/es/skeleton/Button';
import { connect } from 'react-redux';
import { FcSettings } from 'react-icons/fc';
import { FaPlay } from 'react-icons/fa';
import { MdOutlineSettingsBackupRestore } from 'react-icons/md';
import { IoCheckbox } from 'react-icons/io5';

import { Card } from 'src/components';
import { IReduxState } from 'src/store';
import { updateStageTask } from 'src/store/tasks/actions';

import { IProps } from './actions.type';
import { Title, Message } from './actions.style';
import useTask from './actions.use';

const ActionsComponent: React.FC<IProps> = (props) => {
  const { task } = props;

  const { handleRefresh, handleUpdate, handleDelete, loading, sending } =
    useTask(props);

  const buttons = (listId: number) => {
    switch (listId) {
      case 1:
        return (
          <>
            <Button
              loading={sending}
              disabled={
                !task?.technical_advice || !task?.technicianTasks.length
              }
              className="mb-2"
              type="primary"
              onClick={() => handleUpdate(2)}
            >
              <IoCheckbox className="me-2" />
              Finalizar
            </Button>
            {!task?.technical_advice && (
              <small>
                <b>Atenção:</b> Você precisa preencher o parecer técnico para
                finalizar uma ordem de serviço.
              </small>
            )}
          </>
        );
      case 2:
        return (
          <Button disabled={true} className="mb-2" type="primary">
            <IoCheckbox className="me-2" />
            Finalizado
          </Button>
        );
      case 3:
        return (
          <Button
            loading={sending}
            disabled={!task?.technicianTasks.length}
            className="mb-2"
            type="primary"
            onClick={() => handleUpdate(1)}
          >
            <MdOutlineSettingsBackupRestore className="me-2" />
            Restaurar
          </Button>
        );
      default:
        return (
          <Button
            loading={sending}
            disabled={!task?.technicianTasks.length}
            className="mb-2"
            type="primary"
            onClick={() => handleUpdate(1)}
          >
            <FaPlay className="me-2" />
            Iniciar
          </Button>
        );
    }
  };

  useEffect(() => {
    handleRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  return (
    <Card>
      <Title>
        <FcSettings size={20} className="me-2" />
        <Title>Ações</Title>
      </Title>
      <Divider className="mt-2 mb-3" />

      {!loading && !!task ? buttons(task.stage) : <SkeletonButton active />}

      {!!task && (
        <Row gutter={[16, 16]}>
          {props.listId !== 2 && (
            <Col>
              <Message onClick={() => handleUpdate(3)}>Pausar tarefa</Message>
            </Col>
          )}
          <Col>
            <Message onClick={() => handleDelete(String(task.id))}>
              Excluir tarefa
            </Message>
          </Col>
        </Row>
      )}
    </Card>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  task: state.tasks.current,
});

export default connect(mapStateToProps, { updateStageTask })(ActionsComponent);
