import React, { useState } from 'react';
import { Row, Col, Tag, Avatar, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { IoIosCreate } from 'react-icons/io';
import { Draggable } from 'react-beautiful-dnd';

import { IReduxState } from 'src/store';
import { urgencyDegree } from 'src/utils/constants/tasks';
import { fetchTasks } from 'src/store/tasks/actions';

import UpdateTask from './components/update-task/update-task.component';
import { IProps } from './card.type';
import { Card, CardIcons, Icon } from './card.style';

const CardComponent: React.FC<IProps> = (props) => {
  const { card, listId, index, handleReload } = props;

  const [hover, setHover] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const startHover = () => {
    setHover(true);
  };

  const endHover = () => {
    setHover(false);
  };

  const handleEditing = (value?: boolean) => {
    setHover(!hover);
    if (value !== undefined) {
      setEditing(value);
    } else {
      setEditing(!editing);
    }
  };

  const handleVisible = () => {
    setHover(!hover);
    setEditing(false);
    setOpen(!open);
  };

  // const deleteCard = async () => {
  //   dispatch({
  //     type: 'DELETE_LIST_CARD',
  //     payload: { deletedCardId: card?._id, listId },
  //   });
  // };

  return (
    <>
      {card && (
        <Draggable draggableId={card._id} index={index}>
          {(provided: any, snapshot: any) => (
            <Card
              onMouseEnter={startHover}
              onMouseLeave={endHover}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              {hover && (
                <CardIcons>
                  <Icon onClick={() => handleVisible()}>
                    <IoIosCreate />
                  </Icon>
                </CardIcons>
              )}

              <Row gutter={16} className="mb-2">
                <Col span={24}>
                  <h4 className="my-0">{card.text}</h4>
                  <p className="my-0 fs-12 mb-1">{card.description}</p>
                  <small className="d-block">
                    <b>
                      Patrimônio: {card.patrimony?.description} (
                      {card.patrimony?.id})
                    </b>
                  </small>
                  <small className="d-block">
                    <b>
                      Modelo: {card?.patrimony?.model || 'N/A'} | SN:{' '}
                      {card?.patrimony?.serialNumber || 'N/A'}
                    </b>
                  </small>
                </Col>
              </Row>
              <Row gutter={16} justify="space-between">
                <Col>
                  <Tag color={urgencyDegree[card.urgencyDegree].color}>
                    {urgencyDegree[card.urgencyDegree]?.icon}
                    <b>{urgencyDegree[card.urgencyDegree].label}</b>
                  </Tag>
                </Col>
                <Col>
                  {!!card.technicianTasks.length ? (
                    <Avatar.Group
                      maxCount={2}
                      size="small"
                      maxStyle={{
                        color: '#4174b4',
                        backgroundColor: '#d6e5ec',
                      }}
                    >
                      {card.technicianTasks.map((item: any, index: number) => {
                        return (
                          <Tooltip
                            key={index}
                            title={item.technician.name}
                            placement="top"
                          >
                            <Avatar
                              size="small"
                              style={{
                                backgroundColor: '#4174b4',
                                color: 'white',
                                borderColor: item.principal
                                  ? '#ffc107'
                                  : 'white',
                                borderWidth: 2,
                              }}
                            >
                              {item.technician.name[0]}
                            </Avatar>
                          </Tooltip>
                        );
                      })}
                    </Avatar.Group>
                  ) : (
                    ''
                  )}
                </Col>
              </Row>

              <UpdateTask
                open={open}
                handleReload={handleReload}
                editing={editing}
                handleEditing={handleEditing}
                handleVisible={handleVisible}
                cardId={card._id}
                title={`#${card._id} | ${card.description}`}
                listId={Number(listId)}
              />
            </Card>
          )}
        </Draggable>
      )}
    </>
  );
};

const mapStateToProps = (state: IReduxState, ownProps: IProps) => ({
  card: state.board.cardsById?.[ownProps.cardId],
});

export default connect(mapStateToProps, { fetchTasks })(CardComponent);
