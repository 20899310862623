import React, { useState } from 'react';
import { connect } from 'react-redux';

import { fetchTask } from 'src/store/tasks/actions';
import { IReduxState } from 'src/store';

import Descriptions from './components/descriptions/descriptions.component';
import Form from './components/form/form.component';

import { IProps } from './info.type';

const UpdateComponent: React.FC<IProps> = (props) => {
  const { cardId, listId, task } = props;

  const [editing, setEditing] = useState<boolean>(false);

  const handleEditing = () => {
    setEditing(!editing);
  };

  return !editing && task ? (
    <Descriptions task={task} handleEditing={handleEditing} />
  ) : (
    <Form cardId={cardId} listId={listId} handleEditing={handleEditing} />
  );
};

const mapStateToProps = (state: IReduxState) => ({
  task: state.tasks.current,
});

export default connect(mapStateToProps, { fetchTask })(UpdateComponent);
