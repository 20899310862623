import React from 'react';
import { Alert, Form, Input, Button, Popconfirm } from 'antd';
import { connect } from 'react-redux';

import { IProps } from './advice.type';
import { Wrapper, Message, Link } from './advice.style';
import {
  fetchTask,
  updateTask,
  updateStageTask,
} from 'src/store/tasks/actions';
import useTask from './advice.use';
import { useForm } from 'antd/es/form/Form';

const AdviceComponent: React.FC<IProps> = (props) => {
  const { listId } = props;
  const { editing, onEditing, sending, handleSubmit, task } = useTask(props);

  const [form] = useForm();

  return (
    <Wrapper>
      {editing && task ? (
        <Alert
          className="mt-3 mb-2"
          description={
            <Form
              form={form}
              id="form-tech"
              layout="vertical"
              onFinish={handleSubmit}
              initialValues={{ technical_advice: task.technical_advice }}
            >
              <Form.Item name="technical_advice">
                <Input.TextArea
                  rows={4}
                  placeholder="Informe o parecer técnico da ordem de serviço..."
                />
              </Form.Item>

              <Popconfirm
                title="CONFIRMAR PARECER E FINALIZAR"
                description={
                  <div>
                    Deseja informar o parecer técnico e finalizar <br /> essa
                    ordem de serviço?
                  </div>
                }
                onConfirm={async () => {
                  try {
                    await props.updateStageTask(String(task.id), 2);
                    return form.submit();
                  } catch (e) {
                    throw e;
                  }
                }}
                onCancel={() => form.submit()}
              >
                <Button
                  size="small"
                  type="default"
                  loading={sending}
                >
                  <small>SALVAR</small>
                </Button>
              </Popconfirm>
            </Form>
          }
          message={<b>Parecer técnico:</b>}
          type="info"
        />
      ) : (
        <Alert
          className="mt-3 mb-2"
          description={
            <>
              <Message>{task?.technical_advice || 'Não informado'}</Message>
              <Link className="mt-2" onClick={onEditing}>
                {listId === 1
                  ? task?.technical_advice
                    ? 'Editar parecer técnico'
                    : 'Informar parecer técnico'
                  : null}
              </Link>
            </>
          }
          message={<b>Parecer técnico:</b>}
          type="info"
        />
      )}
    </Wrapper>
  );
};

export default connect(undefined, { fetchTask, updateTask, updateStageTask })(
  AdviceComponent
);
