import { useState } from 'react';

import { Notification } from 'src/utils';
import { ITask, TaskStage } from 'src/interfaces/task';

import { IProps } from './actions.type';

const useTask = (props: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  const [task, setTask] = useState<ITask | undefined>(props.task);

  const updateStageTask = async (taskId: any, stage: TaskStage) => {
    try {
      const response = await props.updateStageTask(taskId, stage);

      if (!!response) {
        setTask(response);
      }
    } catch (err: any) {
      Notification.error({
        message:
          'Ocorreu um erro ao carregar as tarefas, tente novamente mais tarde.',
      });

      return false;
    }

    return true;
  };

  const handleUpdate = async (stage: TaskStage) => {
    try {
      const response = await updateStageTask(task?.id, stage);

      if (response) {
        setSending(response);
        props.handleModal();
      }
    } finally {
      setSending(false);
    }
  };

  const handleDelete = async (id: string) => {};

  const handleRefresh = async () => {
    setLoading(true);
    setTimeout(() => setLoading(false), 1000);
  };

  return {
    handleUpdate,
    handleDelete,
    sending,
    task,
    loading,
    setLoading,
    handleRefresh,
  };
};

export default useTask;
