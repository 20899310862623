import dayjs from 'dayjs';

export const rules = {
  description: [{ required: true }],
  stage: [{ required: true }],
  urgencyDegree: [{ required: true }],
  occurredAt: [
    {
      required: true,
      validator: (_: any, value: any) => {
        const date = dayjs();

        if (dayjs(value).isAfter(date)) {
          return Promise.reject(new Error('A data informada é inválida'));
        }

        return Promise.resolve();
      },
    },
  ],
  patrimonyId: [{ required: true }],
  operatorId: [{ required: true }],
  cellId: [{ required: true }],
  status: [{ required: true }],
};

dayjs();
